import * as React from "react";
import * as MainTemplate from "../templates/Main";
import * as SignInTemplate from "../templates/SignIn";
import axios from "axios";
import { useAccount, useConnect, useSignMessage } from "wagmi";
import { useSnackbar } from "notistack";
import * as ethers from "ethers";
import constants from "../constants";

declare global {
    interface Window {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ethereum: any | undefined; // metamask injects global ethereum
    }
}

const apiClient = axios.create({
    baseURL: constants.API_ENDPOINT,
    headers: {
        Authorization: `Bearer ${constants.API_TOKEN}`
    }
});

export const Component = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { connector, isConnected, address } = useAccount();
    const { connect, connectors } = useConnect();

    const [width, setWidth] = React.useState<number>(window.innerWidth);
    const [isMobile, setIsMobile] = React.useState<boolean>(false);
    const [dialogOpen, setDialogOpen] = React.useState<boolean>(false);
    const [loading, setLoading] = React.useState<boolean>(false);
    const [ens, setENS] = React.useState<string>("");
    const [qr, setQr] = React.useState<string>("");
    const [contractAddress, setContractAddress] = React.useState<string>("");
    const [tokenId, setTokenId] = React.useState<string>("");
    const [imageURL, setImageURL] = React.useState<string>("");
    const [email, setEmail] = React.useState<string>("");

    const eventTitle = constants.EVENT_TITLE;
    const eventSubTitle = constants.EVENT_SUBTITLE;
    const eventDesc = constants.EVENT_DESCRIPTION;

    const { signMessage } = useSignMessage({
        async onSuccess(data, variables) {
            console.log(data);
            console.log(variables);

            let responseData;
            try {
                responseData = (
                    await apiClient.post("/issue", {
                        signature: data,
                        message: JSON.parse(variables.message as string)
                    })
                ).data;
            } catch (e: any) {
                console.log({ e });
                console.log(e.response.data);
                enqueueSnackbar(
                    "You are not eligible to issue NFT Access Code.",
                    { variant: "error" }
                );
            }

            setLoading(false);

            if (responseData) {
                setQr(JSON.stringify(responseData));
                // responseData = (await apiClient.post('/verify', responseData)).data
                // console.log(responseData)
            }

        },
        onError() {
            setLoading(false);
            enqueueSnackbar(
                "MetaMask Message Signature: User denied message signature.",
                { variant: "error" }
            );
        }
    });

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }

    React.useEffect(() => {
        window.addEventListener("resize", handleWindowSizeChange);
        return () => {
            window.removeEventListener("resize", handleWindowSizeChange);
        };
    }, []);

    React.useEffect(() => {
        if (width <= 768) {
            setIsMobile(true);
        } else {
            setIsMobile(false);
        }
    }, [width]);

    React.useEffect(() => {
        if (address) {
            // @ts-ignore
            let provider = new ethers.providers.Web3Provider(window.ethereum, "homestead");
            provider.lookupAddress(address)
                .then((ens) => {
                    console.log("ENS:", ens);
                    if (ens) setENS(ens);
                });
        } else {
            setENS("");
        }
    }, [address]);

    const onClickConnect = () => setDialogOpen(true);

    const onSelectConnectionOrDialogClose = React.useCallback(
        (selectedConnectionType: "MetaMask" | "WalletConnect" | "") => {
            setDialogOpen(false);
            if (selectedConnectionType === "") return;
            if (selectedConnectionType === "MetaMask") {
                if (isMobile) {
                    if (window.ethereum === undefined) {
                        if (window.confirm("Open in the MetaMask app?")) {
                            const baseUrl = "dapp://";
                            // eslint-disable-next-line
                            location.href = `${baseUrl}${location.hostname}${location.pathname}${location.search}`;
                            return;
                        }
                        enqueueSnackbar("MetaMask Should Be Installed", {
                            variant: "error"
                        });
                        return;
                    }
                }
                connect({ connector: connectors[0] });
            }
            if (selectedConnectionType === "WalletConnect") {
                connect({ connector: connectors[1] });
            }
        },
        [connect, connectors, enqueueSnackbar, isMobile]
    );

    React.useEffect(() => {
        if (!address) {
            setENS("");
        } else {
        }
    }, [address]);

    React.useEffect(() => {
        apiClient.get(`/events/${constants.EVENT_ID}`).then(r => {
            const { contract_address, token_id, image_url } = r.data;
            setContractAddress(contract_address);
            setTokenId(token_id);
            setImageURL(image_url);
        });
    }, []);


    const onChangeEmail = React.useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    }, []);

    const validateEmail = (email: string) => {
        return email.match(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
    };

    const onClickSubmitEmail = React.useCallback(async () => {
        if (!email || validateEmail(email) === null) {
            enqueueSnackbar("Invalid Email", {
                variant: "error"
            });
            return;
        }
        if (!qr) {
            enqueueSnackbar("QR Should Be Displayed", {
                variant: "error"
            });
            return;
        }

        try {
            await apiClient.post(`/send-email?id=${JSON.parse(qr).ticket_id}`, {
                email
            });
            enqueueSnackbar("Email Sent!", {
                variant: "success"
            });
            return;
        } catch (e) {
            enqueueSnackbar("Failed To Send An email.", {
                variant: "error"
            });
            return;
        }
    }, [email, enqueueSnackbar, qr]);

    const onClickSubmit = React.useCallback(async () => {
        if (!contractAddress || !tokenId || !address || !connector) return;
        try {
            setLoading(true);
            const { data } = await apiClient.get("/nonce", {
                params: {
                    address
                }
            });
            const messageData = {
                issuer: constants.EVENT_ISSUER,
                event_id: constants.EVENT_ID,
                nonce: data.nonce,
                nft: {
                    contract_address: contractAddress,
                    token_id: tokenId
                }
            };
            const message = JSON.stringify(messageData);
            signMessage({ message });
        } catch (err) {
            setLoading(false);
        }
    }, [contractAddress, tokenId, address, connector, signMessage]);

    return React.useMemo(() => {
        if (!isConnected) {
            return (
                <SignInTemplate.Component
                    onClickConnect={onClickConnect}
                    dialogOpen={dialogOpen}
                    onCloseDialog={onSelectConnectionOrDialogClose}
                    selectedConnectionType={""}
                    eventTitle={eventTitle}
                    eventSubTitle={eventSubTitle}
                    eventDesc={eventDesc}
                    imageURL={imageURL}
                />
            );
        } else {
            return (
                <MainTemplate.Component
                    address={address}
                    ens={ens}
                    qr={qr}
                    onSubmit={onClickSubmit}
                    loading={loading || false}
                    eventTitle={eventTitle}
                    eventSubTitle={eventSubTitle}
                    eventDesc={eventDesc}
                    imageURL={imageURL}
                    email={email}
                    onChangeEmail={onChangeEmail}
                    onClickSubmitEmail={onClickSubmitEmail}
                />
            );
        }
    }, [address, dialogOpen, email, ens, eventDesc, eventSubTitle, eventTitle, imageURL, isConnected, loading, onChangeEmail, onClickSubmit, onClickSubmitEmail, onSelectConnectionOrDialogClose, qr]);
};
