const constants = {
    EVENT_TITLE: "Create NFT Access Code",
    EVENT_SUBTITLE: "web3 garage NFT: KODANSHA",
    EVENT_DESCRIPTION: "",
    EVENT_ISSUER: "JRtlKAljmbWD0BX9V7ZF",
    EVENT_ID: "kodansha",
    API_ENDPOINT: "https://henkaku-api-sl6bqje65q-uw.a.run.app/",
    API_TOKEN: "9b2337fee411655e5aa46163ead9477b8a91cb5e54f85b9b298205196983d1b1",
    UI_BUTTON_ISSUE_ACCESS_CODE: "Create NFT Access Code",
    UI_BUTTON_SEND_EMAIL: "Send QR via Email",
}
export default constants;
