import * as React from "react";
import * as Layout from "../layout";
import { styled } from "@mui/material/styles";
import {
    Box,
    Backdrop,
    CircularProgress,
    Stack,
    CardActionArea,
    Grid, TextField
} from "@mui/material";
import { Button } from "@mui/material";
import { QRCodeSVG } from "qrcode.react";
import CardMedia from "@mui/material/CardMedia";
import Card from "@mui/material/Card";
import constants from "../constants";

type Props = {
    address?: string
    loading: boolean
    qr?: string
    ens: string
    onSubmit: () => void
    eventTitle: string
    eventSubTitle: string
    eventDesc: string
    imageURL: string
    email: string
    onClickSubmitEmail: () => void
    onChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void
}

export const Component = (props: Props) => {
    const QRorButton = React.useMemo(() => {
        if (!props.qr)
            return (
                <Grid container sx={{
                    ["@media (min-width:780px)"]: { // eslint-disable-line no-useless-computed-key
                        width: "100%",
                        display: "flex", justifyContent: "center"
                    }
                }}>
                    <Grid item xs={12} md={8}>
                        <ButtonWrapper>
                            <Button
                                variant="outlined"
                                onClick={props.onSubmit}
                                fullWidth
                            >
                                {constants.UI_BUTTON_ISSUE_ACCESS_CODE}
                            </Button>
                        </ButtonWrapper>
                    </Grid>
                </Grid>
            );
        return (
            <Grid container sx={{
                ["@media (min-width:780px)"]: { // eslint-disable-line no-useless-computed-key
                    width: "100%",
                    display: "flex", justifyContent: "center"
                }
            }}>
                <Grid item xs={12} md={8}>
                    <QRCodeWrapper
                        sx={{
                            color: "white",
                            mt: 2,
                            borderRadius: 1,
                            textAlign: "center",
                            fontSize: "1rem",
                            fontWeight: "700"
                        }}
                    >
                        <QRCodeSVG value={props.qr} size={320} />
                    </QRCodeWrapper>
                </Grid>
                <Grid item xs={12} md={8}>
                    <TextFieldWrapper>
                        <TextField
                            label={"email"}
                            value={props.email}
                            onChange={props.onChangeEmail}
                            fullWidth={true} />
                    </TextFieldWrapper>
                </Grid>
                <Grid item xs={12} md={8}>
                    <ButtonWrapper>
                        <Button
                            variant="outlined"
                            onClick={props.onClickSubmitEmail}
                            fullWidth
                        >
                            {constants.UI_BUTTON_SEND_EMAIL}
                        </Button>
                    </ButtonWrapper>
                </Grid>
            </Grid>
        );
    }, [props.email, props.onChangeEmail, props.onClickSubmitEmail, props.onSubmit, props.qr]);

    return (
        <Layout.Main address={props.address}>
            <ContainerItems m={2}>
                <StyledBackDrop open={props.loading}>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        spacing={2}
                    >
                        <TitleBackdrop>Signing message..</TitleBackdrop>
                        <CircularProgress color="inherit" />
                    </Stack>
                </StyledBackDrop>
                <Title sx={{ "margin-bottom": 0, "padding-bottom": 0 }}>{props.eventTitle}</Title>
                <Title sx={{ "margin-top": 0, "padding-top": 0 }}>{props.eventSubTitle}</Title>
                {props.imageURL && <Card elevation={0}>
                  <Grid sx={{ display: "flex", justifyContent: "center" }}>
                    <CardMedia
                      component="img"
                      image={props.imageURL}
                      alt="icon"
                      sx={{
                          ["@media (min-width:780px)"]: { // eslint-disable-line no-useless-computed-key
                              width: "30%"
                          }
                      }}
                    />
                  </Grid>
                </Card>
                }
                {QRorButton}
            </ContainerItems>
        </Layout.Main>
    );
};

const ContainerItems = styled(Box)``;

const StyledBackDrop = styled(Backdrop)`
  z-index: 1001;
  color: '#fff';
`;

const TitleBackdrop = styled("div")`
  z-index: 1002;
  color: white;
  font-weight: bold;
  font-size: 30px;
  line-height: 30px;
  margin-bottom: 20px;
  text-align: center;
  font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
`;

const Title = styled("div")`
  font-size: 18px;
  line-height: 34px;
  margin-bottom: 20px;
  text-align: center;
  font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
  color: rgb(121, 121, 121);
  @media screen and (max-width: 480px) {
    font-size: 24px;
  }
`;

const Sub1Title = styled("div")`
  font-weight: bold;
  font-size: 18px;
  margin-top: 18px;
  margin-bottom: 12px;
  font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
  color: rgb(121, 121, 121);
`;

const ButtonWrapper = styled("div")`
  text-align: center;
  margin-top: 20px;
  @media screen and (min-width: 720px) {
    margin: 20px 120px;
  }
`;

const TextFieldWrapper = styled("div")`
  margin-top: 20px;
  @media screen and (min-width: 720px) {
    margin: 5px 120px;
  }
`;

const QRCodeWrapper = styled(Box)``;
