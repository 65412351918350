import * as React from 'react'
import * as Layout from '../layout'
import { styled } from '@mui/material/styles'
import Grid from '@mui/material/Grid'
import { Button } from '@mui/material'
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import { CardActionArea } from '@mui/material';
import WalletSelectModal from '../components/WalletSelectModal'

type Props = {
    onClickConnect: () => void
    dialogOpen: boolean
    selectedConnectionType: 'MetaMask' | 'WalletConnect' | ''
    onCloseDialog: (value: 'MetaMask' | 'WalletConnect' | '') => void
    eventTitle: string
    eventSubTitle: string
    eventDesc: string
    imageURL: string
}

export const Component = (props: Props) => {
    return (
        <Layout.Main address={''}>
            <WalletSelectModal
                onClose={props.onCloseDialog}
                open={props.dialogOpen}
                selectedValue={''}
            />
            <WhiteBox container direction="column" alignItems="center">
                <ContainerItems item m={2}>
                    <Title>{props.eventTitle}</Title>
                    {props.imageURL && <Card elevation={0}>
                    <Grid sx={{display:'flex', justifyContent:'center'}}>
                        <CardMedia
                          component="img"
                          image={props.imageURL}
                          alt="icon"
                          sx={{
                            ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
                                width: '30%'
                            }
                            }}
                        />
                      </Grid>
                    </Card>}
                    <Sub3Title>{props.eventDesc}</Sub3Title>
                    <Grid container sx={{
                    ['@media (min-width:780px)']: { // eslint-disable-line no-useless-computed-key
                        width: '100%',
                        display:'flex', justifyContent:'center'
                    }
                    }}>
                    <Grid item xs={12} md={8}>
                    <ButtonWrapper>
                        <Button
                            onClick={props.onClickConnect}
                            variant={'outlined'}
                            fullWidth
                        >
                            CONNECT WALLET
                        </Button>
                    </ButtonWrapper>
                    </Grid>
                    </Grid>
                </ContainerItems>
            </WhiteBox>
        </Layout.Main>
    )
}

const WhiteBox = styled(Grid)`
    background-color: white;
    border-radius: 30px;
    justify-content: center;
`

const ContainerItems = styled(Grid)`
    justify-content: center;
    margin-left: 16px;
    margin-right: 16px;
    @media screen and (max-width: 480px) {
        margin: auto;
        width: 100%;
    }
`

const Title = styled('div')`
    text-align: center;
    font-size: 18px;
    line-height: 34px;
    margin-bottom: 20px;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
    color: rgb(121, 121, 121);
    @media screen and (max-width: 480px) {
        font-size: 24px;
    }
`

const Sub1Title = styled('div')`
    font-weight: bold;
    font-size: 18px;
    margin-top: 18px;
    margin-bottom: 12px;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
    color: rgb(121, 121, 121);
`

const Sub3Title = styled('div')`
    font-size: 12px;
    line-height: 12px;
    margin-top: 12px;
    font-family: YakuHanJP, 'Noto Sans JP', sans-serif;
    color: rgb(121, 121, 121);
`

const ButtonWrapper = styled('div')`
    margin-top: 20px;
`
